import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "isCustomer",
    "isSupplier",
    "customerCategory",
    "supplierCategory",
    "customerCategoryContainer",
    "supplierCategoryContainer",
    "customerName",
    "customerEmail",
  ];

  connect() {
    this.showHide();
  }

  showHide() {
    if (this.isCustomerTarget.checked) {
      this.customerCategoryContainerTarget.classList.remove("d-none");
    } else {
      this.customerCategoryContainerTarget.classList.add("d-none");
      this.customerCategoryTarget.value = "";
    }
    if (this.isSupplierTarget.checked) {
      this.supplierCategoryContainerTarget.classList.remove("d-none");
    } else {
      this.supplierCategoryContainerTarget.classList.add("d-none");
      this.supplierCategoryTarget.value = "";
    }
  }

  async checkExisting(event) {
    console.log("checkExisting");
    let name = this.customerNameTarget.value;
    let email = this.customerEmailTarget.value;
    let url = event.target.dataset.url;
    let anagId = this.element.dataset.anagId;

    // if (name != "" && name != null) {
      const response = await post(url, {
        body: JSON.stringify({
          name: name,
          email: email,
          anag_id: anagId,
          target_name: "existing-anags-name-output",
          target_email: "existing-anags-email-output",
        }),
        responseKind: "turbo-stream",
      });
    // }
  }
}
